@font-face {
  font-family: 'font';
  font-style: normal;
  src: url("./FePIrm2.ttf");
}

@font-face {
  font-family: 'small caps';
  src: url("./FePIsc2.ttf");
}

@font-face {
  font-family: 'font';
  font-style: italic;
  src: url("./FePIit2.ttf");
}

body {
  margin: 0px;
  overflow: hidden;
  font-family: 'font';
  background-color: #FFFDEE;
  color: black;
  line-height: 125%;
}

h1 {
  margin: 0px;
  font-weight: normal;
  line-height: 133%;
  margin-bottom: 0.25rem;
}

h2 {
  font-family: 'small caps';
  margin: 0px;
  margin-bottom: 1rem;
}

pre {
  font-family: inherit;
  font-size: large;
  white-space: pre-wrap;
  line-height: 150%;
}

.App {
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 3px;
}

.fullScreen {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  background-color: #FFFDEE;
  -webkit-transition: opacity 1s ease;
  transition: opacity 1s ease;
  opacity: 1;
  z-index: 100;
  overflow-y: scroll;
}

.fullScreen.invisible {
  opacity: 0;
  pointer-events: none;
}

.fullScreen .X {
  color: black;
  text-decoration: none;
  font-weight: bold;
  position: fixed;
  left: calc(50% + 300px + 2rem);
  top: 2rem;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.fullScreen .X:hover {
  color: red;
}

.fullScreen .Book {
  padding-top: 6rem;
  padding-bottom: 12rem;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.centre {
  width: 639px;
  -webkit-transition: opacity 3s ease;
  transition: opacity 3s ease;
}

.mono {
  font-family: 'Courier New', Courier, monospace;
}

.sans {
  font-family: Arial, Helvetica, sans-serif;
}

.b {
  font-weight: bold;
}

.ps {
  height: 16px;
  content: url("./p/leaf.png");
  margin-left: calc(50% - 33px);
}

.alignCentre {
  text-align: center;
}

.alignRight {
  text-align: right;
}

.Window {
  width: 633px;
  height: 625px;
  border: 1px solid black;
  margin-bottom: 1rem;
  padding: 4px;
  padding-right: 2px;
  position: relative;
}

h1.name {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

h1.name span {
  position: relative;
  -webkit-transition: top 0.1s linear;
  transition: top 0.1s linear;
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.small .name {
  font-size: x-large;
}

.small .Window {
  margin-bottom: 0.25rem;
}

.small .buttons a {
  font-size: medium;
}

.W {
  position: absolute;
  left: 4px;
  top: 4px;
  background-color: #FFFDEE;
  width: 633px;
  height: 625px;
  z-index: 50;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  opacity: 1;
}

.W.invisible {
  opacity: 0;
  pointer-events: none;
}

.BioWindow .innerBio {
  padding: 1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 631px;
  height: 625px;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.BioWindow .innerBio .mb {
  margin-bottom: 1rem;
}

.newsletterModal input {
  margin-bottom: 1rem;
  font-size: small !important;
  padding: 0.25rem !important;
  margin-top: 0.25rem;
}

.newsletterModal .buttons {
  margin-top: 1rem;
}

.newsletterModal button {
  font-size: medium;
  font-family: inherit;
  font-weight: normal;
}

.ContactWindow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.ContactWindow div {
  font-size: x-large;
  padding: 1rem;
  text-align: center;
  line-height: 1.2;
}

.ContactWindow div a {
  display: block;
  margin-top: 0.5rem;
  color: blue;
}

.ContactWindow div a:hover {
  color: red;
}

.ContactWindow .leftAlign {
  text-align: left;
  padding-bottom: 0px;
}

.ContactWindow .signup {
  padding-top: 0px;
}

.ContactWindow .signup button {
  color: blue;
  border: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: normal;
  text-decoration: underline;
}

.ContactWindow .signup button:hover {
  color: red;
}

.TreeWindow {
  background-color: rgba(0, 0, 0, 0);
}

.TreeWindow.black {
  background-color: black;
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
}

.TreeWindow .innerTreeWindow {
  padding-right: 2px;
  width: 631px;
  height: 625px;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.TreeWindow .innerTreeWindow .loading {
  width: 631px;
  height: 625px;
  position: absolute;
  font-size: large;
  color: #FFFDEE;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.TreeWindow .innerTreeWindow .block {
  width: 625px;
  height: 625px;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  position: relative;
}

.TreeWindow .innerTreeWindow .block .tree {
  width: 625px;
  position: relative;
  top: -300px;
}

.TreeWindow .innerTreeWindow .block .treetop {
  width: 625px;
  position: relative;
  top: -50px;
  z-index: 10;
}

.TreeWindow .innerTreeWindow .block .treetop.flipped {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.TreeWindow .innerTreeWindow .block .rascal {
  position: absolute;
  left: 524px;
  top: 488px;
  width: 96px;
  z-index: 11;
  cursor: pointer;
}

.TreeWindow .innerTreeWindow .block .door {
  position: absolute;
  left: 311px;
  top: 624px;
  width: 46px;
  z-index: 11;
  cursor: pointer;
}

.TreeWindow .innerTreeWindow .block .mushroom {
  position: absolute;
  left: 122px;
  top: -47px;
  width: 70px;
  z-index: 10;
  cursor: pointer;
}

.TreeWindow .innerTreeWindow .block .star {
  position: absolute;
  left: 211px;
  top: 218px;
  width: 206px;
  z-index: 10;
  cursor: pointer;
}

.TreeWindow .innerTreeWindow .block .acorn {
  position: absolute;
  left: 276px;
  top: 276px;
  width: 74px;
  z-index: 10;
  cursor: pointer;
}

.TreeWindow .innerTreeWindow .block .ufo {
  position: absolute;
  left: 416px;
  top: 100px;
  width: 140px;
  z-index: 10;
  cursor: pointer;
}

.TreeWindow .innerTreeWindow .block .bird {
  position: absolute;
  left: 150px;
  top: 450px;
  width: 125px;
  z-index: 10;
  cursor: pointer;
}

.TreeWindow .innerTreeWindow .block .ring {
  position: absolute;
  left: 162px;
  top: 590px;
  width: 73px;
  z-index: 10;
  cursor: pointer;
}

.TreeWindow .innerTreeWindow .block .square {
  left: 0px;
  top: 0px;
  width: 625px;
}

.TreeWindow .innerTreeWindow .block .square.flipped {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.TreeWindow .innerTreeWindow .block div.book {
  position: absolute;
  z-index: 10;
}

.TreeWindow .innerTreeWindow .block div.book.b0 {
  left: 500px;
  top: 60px;
}

.TreeWindow .innerTreeWindow .block div.book.b1 {
  left: 177px;
  top: 233px;
}

.TreeWindow .innerTreeWindow .block div.book.b2 {
  left: 461px;
  top: 419px;
}

.TreeWindow .innerTreeWindow .block div.book.b2 .title {
  left: -5.5rem;
}

.TreeWindow .innerTreeWindow .block div.book.b2 .title .label {
  top: 2rem;
}

.TreeWindow .innerTreeWindow .block div.book.b3 {
  left: 125px;
  top: 450px;
}

.TreeWindow .innerTreeWindow .block div.book.b3 .title {
  left: -5.5rem;
}

.TreeWindow .innerTreeWindow .block div.book.b3 .title .label {
  top: 2rem;
}

.TreeWindow .innerTreeWindow .block div.book.flipped img.book {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.TreeWindow .innerTreeWindow .block div.book.flipped .title {
  left: -15rem;
}

.TreeWindow .innerTreeWindow .block div.book.flipped .title .label {
  left: auto;
  right: 1.5rem;
}

.TreeWindow .innerTreeWindow .block div.book.flipped.b2 .title {
  left: -12rem;
}

.TreeWindow .innerTreeWindow .block div.book.flipped.b3 .title {
  left: -12rem;
}

.TreeWindow .innerTreeWindow .block div.book .title {
  -webkit-transition: opacity 0.5s ease, top 0.5s ease;
  transition: opacity 0.5s ease, top 0.5s ease;
  top: 0px;
  position: relative;
  width: 15rem;
}

.TreeWindow .innerTreeWindow .block div.book .title.invisible {
  opacity: 0;
  top: 1rem;
}

.TreeWindow .innerTreeWindow .block div.book .title .label {
  font-size: large;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-style: italic;
  border: 1px solid red;
  color: red;
  position: absolute;
  background-color: #FFFDEE;
  line-height: 125%;
  padding: 1rem;
  left: 1.5rem;
  top: 0.5rem;
  margin: 0px;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.TreeWindow .innerTreeWindow .block img.book {
  width: 119px;
  position: relative;
  left: -59px;
  top: -45px;
  cursor: pointer;
}

.TreeWindow .innerTreeWindow .block.flipped .tree {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.TreeWindow .innerTreeWindow .block.flipped .b0 {
  left: 125px !important;
}

.TreeWindow .innerTreeWindow .block.flipped .b1 {
  left: 448px !important;
}

.TreeWindow .innerTreeWindow .block.flipped .b2 {
  left: 164px !important;
}

.TreeWindow .innerTreeWindow .block.flipped .b3 {
  left: 500px !important;
}

h2.count {
  color: red;
}

.centreAlign {
  text-align: center;
}

.EditWindow .modal, .Newsletter .modal {
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.EditWindow .modal .buttons, .Newsletter .modal .buttons {
  margin-top: 1rem;
}

.EditWindow {
  overflow-y: scroll;
}

.EditWindow .Edit {
  padding: 1rem;
  position: relative;
}

.EditWindow .Edit h2 {
  font-size: x-large !important;
  margin-bottom: 1rem;
}

.EditWindow .Edit h4 {
  margin: 0px;
  margin-bottom: 0.5rem;
}

.EditWindow .Edit .bookList {
  margin-bottom: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  gap: 0.5rem;
}

.EditWindow .Edit .bookList .bookTitle {
  cursor: pointer;
}

.EditWindow .Edit .bookList .bookTitle:hover {
  color: red;
}

.EditWindow .Edit .buttons {
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.EditWindow .Edit.editList {
  position: relative;
}

.EditWindow .Edit.editList .X {
  color: black;
  text-decoration: none;
  font-weight: bold;
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.EditWindow .Edit.editList .X:hover {
  color: red;
}

.EditWindow .Edit.editList .shuffleBox {
  position: relative;
  margin-bottom: 1rem;
}

.EditWindow .Edit.editList .shuffleBox .shuffleItem {
  height: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
  -webkit-transition: top 0.25s ease;
  transition: top 0.25s ease;
  width: 100%;
}

.EditWindow .Edit.editList .shuffleBox .shuffleItem .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.EditWindow .Edit.editList .shuffleBox .shuffleItem .item .itemTitle {
  cursor: move;
}

.EditWindow .Edit.editList .shuffleBox .shuffleItem.dragging {
  -webkit-transition: none;
  transition: none;
}

.EditWindow .Edit.editList .shuffleBox .shuffleItem.selected {
  color: red;
}

.EditWindow .Edit textarea, .EditWindow .Edit .fakeTextArea {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: none;
  border: none;
  font-family: 'Courier New', Courier, monospace;
  resize: none;
  font-size: medium;
  line-height: 1.2;
  overflow: hidden;
  padding: 0px;
}

.EditWindow .Edit textarea:focus, .EditWindow .Edit .fakeTextArea:focus {
  outline: none;
}

.EditWindow .Edit textarea pre, .EditWindow .Edit .fakeTextArea pre {
  line-height: 1.2;
  font-size: medium;
}

.EditWindow .Edit .HIDDEN {
  height: 0px;
  overflow: hidden;
  visibility: hidden;
}

.EditWindow .Edit textarea {
  padding-top: 1rem;
}

.EditWindow .Edit .tools {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  height: 2.5rem;
  background-color: #FFFDEE;
  width: 100%;
  padding-top: 1rem;
  margin-top: -1rem;
  border-bottom: 1px dotted black;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.EditWindow .Edit .tools .buttonGroup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
}

.EditWindow .Edit .tools .buttonGroup button {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0px;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}

.EditWindow .Edit .tools .buttonGroup button.wobble {
  width: 2.25rem;
}

.EditWindow .Edit .tools .buttonGroup button svg {
  height: 1.5rem;
  fill: black;
}

.EditWindow .Edit .tools .buttonGroup button:hover svg {
  fill: red;
}

.EditWindow .Edit .tools .buttonGroup button:disabled:hover svg {
  fill: black;
}

.EditWindow .Edit .title {
  padding-top: 1rem;
}

button {
  background-color: #FFFDEE;
  border: 1px solid black;
  padding: 0px;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  cursor: pointer;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  font-size: small;
}

button:hover {
  border: 1px solid red;
  color: red;
}

button:disabled {
  opacity: 0.33;
  color: black;
}

button:disabled:hover {
  color: black;
  border: 1px solid black;
  cursor: default;
}

.Confidential {
  z-index: 200;
  position: fixed;
  top: 1rem;
  left: 1rem;
  opacity: 1;
  -webkit-transition: opacity 0.2s ease, -webkit-transform 0.2s ease-in;
  transition: opacity 0.2s ease, -webkit-transform 0.2s ease-in;
  transition: opacity 0.2s ease, transform 0.2s ease-in;
  transition: opacity 0.2s ease, transform 0.2s ease-in, -webkit-transform 0.2s ease-in;
  pointer-events: none;
}

.Confidential.invisible {
  opacity: 0;
  -webkit-transform: scale(2);
          transform: scale(2);
}

.buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

.buttons a, .buttons .a {
  color: inherit;
  padding: 0px;
  font-family: inherit;
  font-size: large;
  background: none;
  border: none;
  text-decoration: none;
}

.buttons a:hover, .buttons .a:hover {
  color: red;
}

.buttons a.selected, .buttons .a.selected {
  color: red;
  cursor: default;
}

.buttons .a {
  -webkit-animation: fadein 1s ease;
          animation: fadein 1s ease;
}

.buttons.buttonsUnderTheSquareBit {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.Newsletter {
  padding: 1rem;
}

.Newsletter .copyTheseEmailAddresses {
  padding: 0.5rem;
  border: 1px solid black;
  margin: 0.5rem;
  background-color: white;
  font-family: 'Courier New', Courier, monospace;
  text-align: center;
  margin-bottom: 2rem;
}

.Newsletter .emailList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 1rem;
  gap: 0.5rem;
}

.Newsletter .emailList .emailItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

.a {
  cursor: pointer;
}

.modal {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  background-color: #fffdeeb9;
  z-index: 100000000000;
  -webkit-transition: opacity 1s ease;
  transition: opacity 1s ease;
  opacity: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.modal.visible {
  opacity: 1;
}

.modal .X {
  cursor: pointer;
}

.modal .X:hover {
  color: red;
}

.modal .inputBox {
  margin-top: 0.5rem;
}

.modal .inputBox input {
  width: 100%;
  background-color: #FFFDEE;
  border: 1px solid black;
  font-size: inherit;
  font-family: inherit;
  padding: 0.5rem;
}

.modal .inputBox input:focus {
  outline: none;
}

.modal .inputBox input:disabled {
  opacity: 0.33;
}

.modal.errorModal .modalWindow {
  border: 1px solid red;
  color: red;
}

.modal.errorModal .modalWindow button {
  border: 1px solid red;
  color: red;
}

.modal.errorModal .modalWindow button:hover {
  border: 1px solid black;
  color: black;
}

.modal .modalWindow {
  margin: auto;
  max-height: calc(100vh - 6rem);
  padding: 1rem;
  border: 1px solid black;
  background-color: #FFFDEE;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.5rem;
  width: calc(100% - 6rem);
  max-width: 500px;
}

.modal .modalWindow.modalOuter {
  padding: 0px;
  padding-right: 2px;
  position: relative;
}

.modal .modalWindow.modalOuter .titleBar {
  padding: 1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: absolute;
  width: 100%;
  pointer-events: none;
}

.modal .modalWindow.modalOuter .titleBar .X {
  pointer-events: initial;
}

.modal .modalWindow.modalOuter .modalInner {
  padding: 1rem;
  padding-right: calc(2rem - 2px);
  overflow-y: scroll;
}

.modal .modalWindow .titleBar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.Count {
  width: 597px;
  height: 590px;
  padding: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 2rem;
}

.Count .count span {
  font-size: x-large;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
}

.Count .pop {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  height: 1rem;
  text-align: right;
}

.Count .graph {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.Count .graph .datum {
  width: 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.Count .graph .datum div {
  width: 1px;
  background-color: black;
}

.Count .graph .datum.hovered div {
  background-color: red;
}

.Count .graph.month .datum {
  width: 12.16px;
}

.Count .graph.month .datum div {
  width: 12.16px;
}

.Count .graph.week .datum {
  width: 52.14px;
}

.Count .graph.week .datum div {
  width: 52.14px;
}

.Count .graphMode span {
  cursor: pointer;
}

.Count .graphMode span:hover {
  color: red;
}

.Count .graphMode span.red {
  color: red;
  cursor: default;
}

.Count .countries {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  font-size: small;
}

.Count .countries .country {
  min-width: 1.5rem;
}

.Count .countries .country .barContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.Count .countries .country .barContainer .bar {
  padding: 1px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: black;
  width: calc(100% - 2px);
}

.Count .countries .country .countryCode {
  text-align: center;
  height: 0.75rem;
}

.Count .countries .country .countryNumber {
  text-align: center;
  height: 0.75rem;
}

.MEDIUM h2 {
  font-size: large;
}

.MEDIUM .centre {
  width: 483px;
}

.MEDIUM .Window {
  width: 477px;
  height: 469px;
}

.MEDIUM .W {
  width: 477px;
  height: 469px;
}

.MEDIUM .Count {
  width: 441px;
  height: 435px;
}

.MEDIUM .ContactWindow div {
  font-size: large;
}

.MEDIUM .ContactWindow div a {
  margin-top: 0px;
}

.MEDIUM .BioWindow .innerBio {
  width: 475px;
  height: 469px;
}

.MEDIUM .TreeWindow .innerTreeWindow {
  width: 475px;
  height: 469px;
}

.MEDIUM .TreeWindow .innerTreeWindow .loading {
  width: 475px;
  height: 469px;
}

.MEDIUM .TreeWindow .innerTreeWindow .block {
  width: 469px;
  height: 469px;
}

.MEDIUM .TreeWindow .innerTreeWindow .block .tree {
  width: 469px;
  top: -225px;
}

.MEDIUM .TreeWindow .innerTreeWindow .block .treetop {
  width: 469px;
  top: -37px;
}

.MEDIUM .TreeWindow .innerTreeWindow .block .rascal {
  left: 393px;
  top: 366px;
  width: 72px;
}

.MEDIUM .TreeWindow .innerTreeWindow .block .door {
  left: 233px;
  top: 468px;
  width: 35px;
}

.MEDIUM .TreeWindow .innerTreeWindow .block .mushroom {
  left: 92px;
  top: -35px;
  width: 53px;
}

.MEDIUM .TreeWindow .innerTreeWindow .block .star {
  left: 158px;
  top: 164px;
  width: 155px;
}

.MEDIUM .TreeWindow .innerTreeWindow .block .acorn {
  left: 207px;
  top: 207px;
  width: 56px;
}

.MEDIUM .TreeWindow .innerTreeWindow .block .ufo {
  left: 312px;
  top: 75px;
  width: 105px;
}

.MEDIUM .TreeWindow .innerTreeWindow .block .bird {
  left: 113px;
  top: 338px;
  width: 94px;
}

.MEDIUM .TreeWindow .innerTreeWindow .block .ring {
  left: 122px;
  top: 443px;
  width: 55px;
}

.MEDIUM .TreeWindow .innerTreeWindow .block .square {
  width: 469px;
}

.MEDIUM .TreeWindow .innerTreeWindow .block div.book.b0 {
  left: 376px;
  top: 45px;
}

.MEDIUM .TreeWindow .innerTreeWindow .block div.book.b1 {
  left: 133px;
  top: 175px;
}

.MEDIUM .TreeWindow .innerTreeWindow .block div.book.b2 {
  left: 346px;
  top: 314px;
}

.MEDIUM .TreeWindow .innerTreeWindow .block div.book.b2 .title {
  left: -5rem;
}

.MEDIUM .TreeWindow .innerTreeWindow .block div.book.b2 .title .label {
  top: 1.5rem;
}

.MEDIUM .TreeWindow .innerTreeWindow .block div.book.b3 {
  left: 94px;
  top: 338px;
}

.MEDIUM .TreeWindow .innerTreeWindow .block div.book.b3 .title {
  left: -5rem;
}

.MEDIUM .TreeWindow .innerTreeWindow .block div.book.b3 .title .label {
  top: 1.5rem;
}

.MEDIUM .TreeWindow .innerTreeWindow .block div.book.flipped .title {
  left: -12rem;
}

.MEDIUM .TreeWindow .innerTreeWindow .block div.book.flipped.b2 .title {
  left: -8rem;
}

.MEDIUM .TreeWindow .innerTreeWindow .block div.book.flipped.b3 .title {
  left: -8rem;
}

.MEDIUM .TreeWindow .innerTreeWindow .block div.book .title {
  width: 12rem;
}

.MEDIUM .TreeWindow .innerTreeWindow .block div.book .title .label {
  font-size: medium;
  padding: 0.75rem;
}

.MEDIUM .TreeWindow .innerTreeWindow .block img.book {
  width: 89px;
  left: -45px;
  top: -34px;
}

.MEDIUM .TreeWindow .innerTreeWindow .block.flipped .b0 {
  left: 93px !important;
}

.MEDIUM .TreeWindow .innerTreeWindow .block.flipped .b1 {
  left: 336px !important;
}

.MEDIUM .TreeWindow .innerTreeWindow .block.flipped .b2 {
  left: 123px !important;
}

.MEDIUM .TreeWindow .innerTreeWindow .block.flipped .b3 {
  left: 375px !important;
}

.MEDIUM .fullScreen .X {
  left: calc(50% + 225px + 1rem);
  top: 1rem;
}

.MEDIUM .fullScreen .Book {
  padding-top: 3rem;
  padding-bottom: 3rem;
  width: 450px;
}

.SMALL h2 {
  font-size: large;
}

.SMALL .SoppilyRomanticTheologicalScienceFilosophy {
  font-size: medium;
  font-family: 'font';
}

.SMALL .centre {
  width: 327px;
}

.SMALL .Window {
  width: 321px;
  height: 313px;
}

.SMALL .W {
  width: 321px;
  height: 313px;
}

.SMALL .Count {
  width: 317px;
  height: 303px;
  gap: 0.5rem;
  padding: 0px;
  padding-top: 0.5rem;
}

.SMALL .Count .graph {
  width: 300px;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  overflow-x: hidden;
  overflow-y: auto;
}

.SMALL .ContactWindow div {
  font-size: medium;
}

.SMALL .BioWindow .innerBio {
  width: 319px;
  height: 313px;
}

.SMALL .TreeWindow .innerTreeWindow {
  width: 319px;
  height: 313px;
}

.SMALL .TreeWindow .innerTreeWindow .loading {
  width: 319px;
  height: 313px;
}

.SMALL .TreeWindow .innerTreeWindow .block {
  width: 313px;
  height: 313px;
}

.SMALL .TreeWindow .innerTreeWindow .block .tree {
  width: 313px;
  top: -150px;
}

.SMALL .TreeWindow .innerTreeWindow .block .treetop {
  width: 313px;
  top: -25px;
}

.SMALL .TreeWindow .innerTreeWindow .block .rascal {
  left: 262px;
  top: 244px;
  width: 48px;
}

.SMALL .TreeWindow .innerTreeWindow .block .door {
  left: 155px;
  top: 312px;
  width: 23px;
}

.SMALL .TreeWindow .innerTreeWindow .block .mushroom {
  left: 61px;
  top: -24px;
  width: 35px;
}

.SMALL .TreeWindow .innerTreeWindow .block .star {
  left: 105px;
  top: 109px;
  width: 103px;
}

.SMALL .TreeWindow .innerTreeWindow .block .acorn {
  left: 138px;
  top: 138px;
  width: 37px;
}

.SMALL .TreeWindow .innerTreeWindow .block .ufo {
  left: 208px;
  top: 50px;
  width: 70px;
}

.SMALL .TreeWindow .innerTreeWindow .block .bird {
  left: 75px;
  top: 225px;
  width: 63px;
}

.SMALL .TreeWindow .innerTreeWindow .block .ring {
  left: 81px;
  top: 295px;
  width: 37px;
}

.SMALL .TreeWindow .innerTreeWindow .block .square {
  width: 313px;
}

.SMALL .TreeWindow .innerTreeWindow .block div.book.b0 {
  left: 250px;
  top: 30px;
}

.SMALL .TreeWindow .innerTreeWindow .block div.book.b1 {
  left: 89px;
  top: 117px;
}

.SMALL .TreeWindow .innerTreeWindow .block div.book.b2 {
  left: 230px;
  top: 210px;
}

.SMALL .TreeWindow .innerTreeWindow .block div.book.b2 .title {
  left: -3.5rem;
}

.SMALL .TreeWindow .innerTreeWindow .block div.book.b2 .title .label {
  top: 1rem;
}

.SMALL .TreeWindow .innerTreeWindow .block div.book.b3 {
  left: 63px;
  top: 225px;
}

.SMALL .TreeWindow .innerTreeWindow .block div.book.b3 .title {
  left: -3.5rem;
}

.SMALL .TreeWindow .innerTreeWindow .block div.book.b3 .title .label {
  top: 1rem;
}

.SMALL .TreeWindow .innerTreeWindow .block div.book.flipped .title {
  left: -9rem;
}

.SMALL .TreeWindow .innerTreeWindow .block div.book.flipped.b2 .title {
  left: -5rem;
}

.SMALL .TreeWindow .innerTreeWindow .block div.book.flipped.b3 .title {
  left: -5rem;
}

.SMALL .TreeWindow .innerTreeWindow .block div.book .title {
  width: 9rem;
}

.SMALL .TreeWindow .innerTreeWindow .block div.book .title .label {
  font-size: small;
  padding: 0.5rem;
  top: 0px;
}

.SMALL .TreeWindow .innerTreeWindow .block img.book {
  width: 60px;
  left: -30px;
  top: -23px;
}

.SMALL .TreeWindow .innerTreeWindow .block.flipped .b0 {
  left: 63px !important;
}

.SMALL .TreeWindow .innerTreeWindow .block.flipped .b1 {
  left: 224px !important;
}

.SMALL .TreeWindow .innerTreeWindow .block.flipped .b2 {
  left: 83px !important;
}

.SMALL .TreeWindow .innerTreeWindow .block.flipped .b3 {
  left: 250px !important;
}

.SMALL .fullScreen .X {
  left: calc(50% + 190px + 1rem);
  top: 1rem;
}

.SMALL .fullScreen .Book {
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 380px;
}

.SMALL pre {
  font-size: medium;
}

@media (max-width: 460px) {
  .fullScreen .X {
    left: calc(50% + 140px + 1rem);
    top: 1rem;
  }
  .fullScreen .Book {
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 280px;
  }
}
