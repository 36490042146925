$yella: #FFFDEE;
$yellaTransparent: #fffdeeb9;
$black: black;
$red: red;

@font-face {
    font-family: 'font';
    font-style: normal;
    src: url('./FePIrm2.ttf');
}

@font-face {
    font-family: 'small caps';
    src: url('./FePIsc2.ttf');
}

@font-face {
    font-family: 'font';
    font-style: italic;
    src: url('./FePIit2.ttf');
}

body {
    margin: 0px;
    overflow: hidden;
    font-family: 'font';
    background-color: $yella;
    color:$black;
    line-height: 125%;
}

h1 {
    margin: 0px;
    font-weight: normal;
    line-height: 133%;
    margin-bottom: 0.25rem;
}

h2 {
    font-family: 'small caps';
    margin: 0px;
    margin-bottom: 1rem;
}

pre {
    font-family: inherit;
    font-size: large;
    white-space: pre-wrap;
    line-height: 150%;
}

.App {
    width:100%;
    height:100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
::-webkit-scrollbar-thumb {
    background: $black;
    border-radius: 3px;
}

$w0: 625px;

.fullScreen {
    position:fixed;
    left:0px;
    top:0px;
    width:100%;
    height:100vh;
    background-color: $yella;
    transition: opacity 1s ease;
    opacity: 1;
    z-index: 100;
    overflow-y: scroll;
    &.invisible {
        opacity: 0;
        pointer-events: none;
    }
    .X {
        color: black;
        text-decoration: none;
        font-weight: bold;
        position:fixed;
        left:calc(50% + 300px + 2rem);
        top:2rem;
        cursor: pointer;
        user-select: none;
        &:hover { color: $red; }
    }
    .Book {
        padding-top:6rem; 
        padding-bottom:12rem;
        width:600px;
        margin-left: auto;
        margin-right:auto;
    }
}

.centre {
    width: 639px; // w0 + 14
    transition: opacity 3s ease;
}

.mono {
    font-family: 'Courier New', Courier, monospace;
}

.sans {
    font-family: Arial, Helvetica, sans-serif;
}

.b {
    font-weight: bold;
}
.ps {
    height:16px;
    content: url('./p/leaf.png');
    margin-left:calc(50% - 33px);
}

.alignCentre {
    // width:100%;
    // display:block;
    text-align: center;
}

.alignRight {
    // display:block;
    text-align: right;
}

.Window {
    width: 633px; // w0 + 8
    height:$w0;
    border: 1px solid black;
    margin-bottom:1rem;
    padding:4px;
    padding-right: 2px;
    position:relative;
}

h1.name {
    span {
        position:relative;
        transition: top 0.1s linear;
    }
    user-select: none;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.small {
    .name {
        font-size: x-large;
    }
    .Window {
        margin-bottom:0.25rem;
    }
    .buttons {
        a {
            font-size: medium;
        }
    }
}


.W {
    position:absolute;
    left:4px;
    top:4px;
    background-color: $yella;
    width: 633px;
    height:$w0;
    z-index: 50;
    transition: opacity 0.5s ease;
    opacity: 1;
    &.invisible {
        opacity: 0;
        pointer-events: none;
    }
}

.BioWindow {
    .innerBio {
        padding: 1rem;
        box-sizing: border-box;
        width: 631px; // w0 + 6
        height:$w0;
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        .mb {
            margin-bottom: 1rem;
        }
    }
}

.newsletterModal {
    input {
        margin-bottom:1rem;
        font-size: small !important;
        padding:0.25rem !important;
        margin-top:0.25rem;
    }
    .buttons {
        margin-top:1rem;
    }
    button {
        font-size: medium;
        font-family: inherit;
        font-weight: normal;
    }
}

.ContactWindow {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    div {
        a {
            display: block;
            margin-top: 0.5rem;
            color: blue;
            &:hover {
                color:$red;
            }
        }
        font-size: x-large;
        padding:1rem;
        text-align: center;
        line-height: 1.2;
    }
    .leftAlign {
        text-align: left;
        padding-bottom:0px;
    }
    .signup {
        padding-top:0px;
        button {
            color: blue;
            border: none;
            font-family: inherit;
            font-size: inherit;
            font-weight: normal;
            text-decoration: underline;
            &:hover {
                color: $red;
            }
        }
    }
}

.TreeWindow {
    // position:absolute;
    // left:4px;
    // top:4px;
    // width: 633px; // w0 + 8
    // height:$w0;
    // border: 1px solid black;
    // margin-bottom:1rem;
    // padding:4px;
    // padding-right: 2px;
    &.black {
        background-color: black;
        transition: background-color 0.2s ease;
    }
    background-color: rgba(0,0,0,0);
    .innerTreeWindow {
        // background-color: black;
        padding-right: 2px;
        width: 631px; // w0 + 6
        height:$w0;
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        display: flex;
        flex-direction: column;
        .loading {
            width:631px;
            height:$w0;
            position: absolute;
            font-size: large;
            color: $yella;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .block {
            width:$w0;
            height:$w0;
            transition: opacity 0.5s ease;
            position: relative;
            .tree {
                width:$w0;
                position:relative;
                top:-300px; // w0 / 2.083333333
            }
            .treetop {
                width:$w0;
                position:relative;
                top:-50px;
                z-index: 10;
                &.flipped {
                    transform: scaleX(-1)
                }
            }
            .rascal {
                position:absolute;
                left:524px;
                top:488px;
                width:96px;
                z-index: 11;
                cursor: pointer;
            }
            .door {
                position:absolute;
                left:311px;
                top:624px;
                width:46px;
                z-index: 11;
                cursor: pointer;
            }
            .mushroom {
                position:absolute;
                left:122px;
                top:-47px;
                width:70px;
                z-index: 10;
                cursor: pointer;
            }
            .star {
                position:absolute;
                left:211px;
                top:218px;
                width:206px;
                z-index: 10;
                cursor: pointer;
            }
            .acorn {
                position:absolute;
                left:276px;
                top:276px;
                width:74px;
                z-index: 10;
                cursor: pointer;
            }
            .ufo {
                position:absolute;
                left:416px;
                top:100px;
                width:140px;
                z-index: 10;
                cursor: pointer;
            }
            .bird {
                position:absolute;
                left:150px;
                top:450px;
                width:125px;
                z-index: 10;
                cursor: pointer;
            }
            .ring {
                position:absolute;
                left:162px;
                top:590px;
                width:73px;
                z-index: 10;
                cursor: pointer;
            }
            .square { 
                // position:absolute;
                // z-index: 10;
                left:0px;
                top:0px;
                width:$w0;
                &.flipped {
                    transform: scaleX(-1)
                }
            } 

            $b0l: 500px; // w0 / 1.25
            $b0t: 60px; // w0 / 10.416666

            $b1l:177px; // w0 / 3.531
            $b1t:233px; // w0 / 2.682

            $b2l:461px; // w0 / 1.356
            $b2t:419px; // w0 / 1.492

            $b3l:125px; // w0 / 5
            $b3t:450px; // w0 / 1.38888


            div.book {
                position:absolute;
                z-index: 10;
                // width:10rem;
                &.b0 {
                    left: $b0l;
                    top: $b0t;
                }
                &.b1 {
                    left: $b1l;
                    top: $b1t;
                }
                &.b2 {
                    left: $b2l;
                    top: $b2t;
                    .title {
                        left:-5.5rem;
                        .label { top:2rem }
                    }
                }
                &.b3 {
                    left: $b3l;
                    top: $b3t;
                    .title {
                        left:-5.5rem;
                        .label { top:2rem }
                    }
                }
                &.flipped {
                    img.book { transform: scaleX(-1) }
                    .title{
                        left:-15rem;
                        .label {
                            left: auto;
                            right:1.5rem;
                        }
                    }
                    &.b2 {
                        .title {
                            left:-12rem; // -5.5 - 15/2
                        }
                    }
                    &.b3 {
                        .title {
                            left:-12rem;
                        }
                    }
                }
                .title {
                    transition: opacity 0.5s ease, top 0.5s ease;
                    top:0px;
                    position:relative;
                    width:15rem;
                    &.invisible {
                        opacity: 0;
                        top:1rem;
                    }
                    .label {
                        font-size: large;
                        user-select: none;
                        font-style: italic;
                        border:1px solid red;
                        color: $red;
                        position:absolute;
                        background-color: $yella;
                        line-height: 125%;
                        padding:1rem;
                        left:1.5rem;
                        top:0.5rem;
                        margin:0px;
                        transition: opacity 0.2s linear;
                    }
                }
            }
            img.book {
                width: 119px;
                position:relative;
                left: -59px;
                top: -45px;
                cursor: pointer;
            }
            &.flipped {
                .tree { transform: scaleX(-1) }
                .b0 { left: 125px !important }
                .b1 { left: 448px !important}
                .b2 { left: 164px !important}
                .b3 { left: 500px !important}
            }
        }
    }
}

h2.count {
    color: $red;
}

.centreAlign {
    text-align: center;
}

.EditWindow, .Newsletter {
    .modal {
        transition: opacity 0.25s ease;
        .buttons {
            margin-top:1rem;
        }
    }
}

.EditWindow {
    overflow-y: scroll;
    .Edit {
        padding:1rem;
        h2 {
            font-size: x-large !important;
            margin-bottom:1rem;
        }
        h4 {
            margin:0px;
            margin-bottom:0.5rem;
        }
        .bookList {
            margin-bottom: 2rem;
            display: flex;
            flex-direction:column-reverse;
            gap:0.5rem;
            .bookTitle {
                cursor: pointer;
                &:hover {
                    color: $red;
                }
            }
        }
        .buttons {
            gap:0.5rem;
            margin-top:0.5rem;
        }
        &.editList {
            position: relative;
            .X {
                color: black;
                text-decoration: none;
                font-weight: bold;
                position:absolute;
                right:1rem;
                top:1rem;
                cursor: pointer;
                user-select: none;
                &:hover { color: $red; }
            }
            .shuffleBox {
                position:relative;
                .shuffleItem {
                    height:2rem;
                    display: flex;
                    align-items: center;
                    user-select: none;
                    position: absolute;
                    transition: top 0.25s ease;
                    width:100%;
                    .item {
                        display: flex;
                        width:100%;
                        justify-content: space-between;
                        flex-direction: row;
                        .itemTitle {
                            cursor:move;
                        }
                    }
                    &.dragging {
                        transition: none;
                    }
                    &.selected {
                        color:$red;
                    }
                }
                margin-bottom:1rem;
            }
        }

        textarea, .fakeTextArea {
            width:100%;
            box-sizing: border-box;
            background:none;
            border:none;
            font-family: 'Courier New', Courier, monospace;
            resize: none;
            font-size: medium;
            line-height: 1.2;
            overflow: hidden;
            padding:0px;
            &:focus {
                outline: none;
            }
            pre {
                line-height: 1.2;
                font-size: medium;
            }
        }
        .HIDDEN {
            height: 0px;
            overflow: hidden;
            visibility: hidden;
        }
        textarea {
            padding-top:1rem;
        }
        position: relative;
        .tools {
            position:sticky;
            top:0px;
            height:2.5rem;
            background-color: $yella;
            width:100%;
            padding-top:1rem;
            margin-top:-1rem;
            border-bottom: 1px dotted black;
            box-sizing: content-box;
            display: flex;
            gap:0.5rem;
            justify-content: space-between;
            .buttonGroup {
                display: flex;
                gap:0.5rem;
                button {
                    width:1.5rem;
                    height:1.5rem;
                    padding:0px;
                    box-sizing: content-box;
                    &.wobble {
                        width:2.25rem;
                    }
                    svg {
                        height:1.5rem;
                        fill: $black;
                    }
                    &:hover {
                        svg {
                            fill:$red;
                        }
                    }
                    &:disabled {
                        &:hover {
                            svg {
                                fill: $black;
                            }
                        }
                    }
                }
            }
        }
        .title {
            padding-top:1rem;
        }
    }
}

button {
    background-color: $yella;
    border:1px solid $black;
    padding:0px;
    padding-left:0.25rem;
    padding-right:0.25rem;
    cursor: pointer;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    font-size: small;
    &:hover {
        border: 1px solid $red;
        color: $red;
    }
    &:disabled {
        opacity: 0.33;
        color: $black;
        &:hover {
            color: $black;
            border:1px solid $black;
            cursor:default;
        }
    }
}

.Confidential {
    z-index: 200;
    position:fixed;
    top:1rem;
    left:1rem;
    opacity: 1;
    transition: opacity 0.2s ease, transform 0.2s ease-in;
    pointer-events: none;
    &.invisible {
        opacity: 0;
        transform: scale(2);
    }
}

.buttons {
    display: flex;
    gap:1rem;
    a, .a {
        color: inherit;
        padding:0px;
        font-family: inherit;
        font-size: large;
        background: none;
        border:none;
        &:hover {
            color: $red;
        }
        text-decoration: none;
        &.selected {
            color: $red;
            cursor: default;
        }
    }
    .a {
        animation: fadein 1s ease;
    }
    &.buttonsUnderTheSquareBit {
        flex-wrap: wrap;
    }
}

.Newsletter {
    padding:1rem;
    .copyTheseEmailAddresses {
        padding:0.5rem;
        border:1px solid black;
        margin:0.5rem;
        background-color: white;
        font-family: 'Courier New', Courier, monospace;
        text-align: center;
        margin-bottom:2rem;
    }
    .emailList {
        display: flex;
        flex-direction: column;
        margin-top:1rem;
        gap:0.5rem;
        .emailItem {
            display: flex;
            gap:1rem;
        }
    }
}

.a {
    cursor: pointer;
}

.modal {
    position:fixed;
    left:0px;
    top:0px;
    width: 100%;
    height: 100vh;
    background-color: $yellaTransparent;
    z-index:100000000000;
    transition: opacity 1s ease;
    opacity: 0;
    &.visible {
        opacity: 1;
    }
    display: flex;
    .X {
        cursor: pointer;
        &:hover {
            color:$red;
        }
    }
    .inputBox {
        margin-top:0.5rem;
        input {
            width:100%;
            background-color: $yella;
            border:1px solid $black;
            font-size: inherit;
            font-family: inherit;
            padding:0.5rem;
            &:focus {
                outline: none;
            }
            &:disabled {
                opacity: 0.33;
            }
        }
    }
    &.errorModal {
        .modalWindow {
            border:1px solid $red;
            color: $red;
            button {
                border:1px solid $red;
                color:$red;
                &:hover {
                    border:1px solid $black;
                    color: $black;
                }
            }
        }
    }
    .modalWindow {
        margin:auto;
        max-height: calc(100vh - 6rem);
        padding:1rem;
        border:1px solid $black;
        background-color: $yella;
        display: flex;
        flex-direction: column;
        gap:0.5rem;
        width: calc(100% - 6rem);
        max-width: 500px;
        &.modalOuter {
            padding:0px;
            padding-right:2px;
            position:relative;
            .titleBar{
                padding:1rem;
                box-sizing: border-box;
                position:absolute;
                width:100%;
                pointer-events: none;
                .X {
                    pointer-events: initial;
                }
            }
            .modalInner {
                padding:1rem;
                padding-right:calc(2rem - 2px);
                overflow-y: scroll;
            }
        }

        .titleBar{
            display: flex;
            justify-content: flex-end;
        }
    }
}

.Count {
    width:597px;
    height:590px;
    padding:1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:2rem;
    .count {
        span {
            font-size: x-large;
            font-family:'Times New Roman', Times, serif;
            font-weight: bold;
        }
    }
    .pop {
        font-family:'Times New Roman', Times, serif;
        font-weight: bold;
        height:1rem;
        text-align: right;
    }
    .graph {
        display: flex;
        position: relative;
        .datum {
            width:1px;
            display: flex;
            align-items: flex-end;
            div {
                width:1px;
                background-color: $black;
            }
            &.hovered {
                div {
                    background-color: $red;
                }
            }
        }
        &.month {
            .datum {
                width:12.16px;
                div {
                    width:12.16px;
                }
            }
        }
        &.week {
            .datum {
                width:52.14px;
                div {
                    width:52.14px;
                }
            }
        }
    }
    .graphMode {
        span {
            cursor: pointer;
            &:hover {
                color: $red;
            }
            &.red {
                color:$red;
                cursor: default;
            }
        }
    }
    .countries {
        display: inline-flex;
        font-family: 'Courier New', Courier, monospace;
        font-weight: bold;
        font-size: small;
        .country {
            min-width: 1.5rem;
            .barContainer {
                display: flex;
                align-items: flex-end;
                .bar {
                    padding:1px;
                    box-sizing: border-box;
                    background-color: $black;
                    width:calc(100% - 2px);
                }
            }
            .countryCode {
                text-align: center;
                height:0.75rem;
            }
            .countryNumber {
                text-align: center;
                height:0.75rem;
            }
        }
    }
}

// w0: 625
$w1: 469px; //w0 * 0.75

.MEDIUM {
    h2 {
        font-size: large;
    }
    .centre { 
        width: 483px; // w1 + 14
    }
    .Window {
        width: 477px;
        height:$w1;
    }
    .W {
        width: 477px;
        height:$w1;
    }
    .Count {
        width: 441px;
        height:435px;
    }
    .ContactWindow {
        div {
            font-size: large;
            a {
                margin-top: 0px;
            }
        }
    }
    .BioWindow{
        .innerBio {
            width: 475px; // w1 + 6
            height:$w1;
        }
    }
    .TreeWindow {
        .innerTreeWindow {
            width: 475px; // w1 + 6
            height:$w1;
            .loading {
                width:475px;
                height:$w1;
            }
            .block {
                width:$w1;
                height:$w1;
                .tree {
                    width:$w1;
                    top:-225px; // w1 / 2.083333333
                }
                .treetop {
                    width:$w1;
                    top:-37px; // w1 / 2.083333333
                }
                .rascal {
                    left:393px;
                    top:366px;
                    width:72px;
                }
                .door {
                    left:233px;
                    top:468px;
                    width:35px;
                }
                .mushroom {
                    left:92px;
                    top:-35px;
                    width:53px;
                }
                .star {
                    left:158px;
                    top:164px;
                    width:155px;
                }
                .acorn {
                    left:207px;
                    top:207px;
                    width:56px;
                }
                .ufo {
                    left:312px;
                    top:75px;
                    width:105px;
                }
                .bird {
                    left:113px;
                    top:338px;
                    width:94px;
                }
                .ring {
                    left:122px;
                    top:443px;
                    width:55px;
                }
                .square { width:$w1 }

                $b0l: 376px; // 469 / 1.25
                $b0t: 45px; // 469 / 10.416666
    
                $b1l:133px; // 469 / 3.531
                $b1t:175px; // 469 / 2.682
    
                $b2l:346px; // 469 / 1.356
                $b2t:314px; // 469 / 1.492
    
                $b3l:94px; // 469 / 5
                $b3t:338px; // 469 / 1.38888
                div.book {
                    &.b0 {
                        left: $b0l;
                        top: $b0t;
                    }
                    &.b1 {
                        left: $b1l;
                        top: $b1t;
                    }
                    &.b2 {
                        left: $b2l;
                        top: $b2t;
                        .title {
                            left:-5rem;
                            .label { top:1.5rem }
                        }
                    }
                    &.b3 {
                        left: $b3l;
                        top: $b3t;
                        .title {
                            left:-5rem;
                            .label { top:1.5rem }
                        }
                    }
                    &.flipped {
                        .title { 
                            left:-12rem;

                        }
                        &.b2 {
                            .title {
                                left:-8rem; // -5.5 - 15/2
                            }
                        }
                        &.b3 {
                            .title {
                                left:-8rem;
                            }
                        }
                    }
                    .title {
                        width:12rem;
                        .label { 
                            font-size: medium ;
                            padding:0.75rem;
                        }
                    }
                }
                img.book {
                    width: 89px;
                    left: -45px; // 89 / 2
                    top: -34px; // 89 / 2.644444444
                }
                &.flipped {
                    .b0 { left: 93px !important }
                    .b1 { left: 336px !important }
                    .b2 { left: 123px !important }
                    .b3 { left: 375px !important }
                }
            }
        }
    }
    .fullScreen {
        .X {
            left:calc(50% + 225px + 1rem);
            top:1rem;
        }
        .Book {
            padding-top:3rem;
            padding-bottom:3rem;
            width:450px;
        }
    }
}

// w0: 625
$w2: 313px; //w0 * 0.5

.SMALL {
    h2 {
        font-size: large;
    }
    .SoppilyRomanticTheologicalScienceFilosophy {
        font-size: medium;
        font-family: 'font';
    }
    .centre { 
        width: 327px; // w2 + 14
    }
    .Window {
        width: 321px;
        height:$w2;
    }
    .W {
        width: 321px;
        height:$w2;
    }
    .Count {
        width: 317px;
        height:303px;
        gap:0.5rem;
        padding:0px;
        padding-top:0.5rem;
        .graph {
            width:300px;
            justify-content: end;
            overflow-x: hidden;
            overflow-y:auto;
        }
    }
    .ContactWindow {
        div {
            font-size: medium;
        }
    }
    .BioWindow{
        .innerBio {
            width: 319px; // w2 + 6
            height:$w2;
        }
    }
    .TreeWindow {
        .innerTreeWindow {
            width: 319px; // w2 + 6
            height:$w2;
            .loading {
                width:319px;
                height:$w2;
            }
            .block {
                width:$w2;
                height:$w2;
                .tree {
                    width:$w2;
                    top:-150px; // w0 / 2.083333333
                }
                .treetop {
                    width:$w2;
                    top:-25px;
                }
                .rascal {
                    left:262px;
                    top:244px;
                    width:48px;
                }
                .door {
                    left:155px;
                    top:312px;
                    width:23px;
                }
                .mushroom {
                    left:61px;
                    top:-24px;
                    width:35px;
                }
                .star {
                    left:105px;
                    top:109px;
                    width:103px;
                }
                .acorn {
                    left:138px;
                    top:138px;
                    width:37px;
                }
                .ufo {
                    left:208px;
                    top:50px;
                    width:70px;
                }
                .bird {
                    left:75px;
                    top:225px;
                    width:63px;
                }
                .ring {
                    left:81px;
                    top:295px;
                    width:37px;
                }
                .square { width:$w2 }
                $b0l: 250px; // 313 / 1.25
                $b0t: 30px; // 313 / 10.416666
    
                $b1l:89px; // 313 / 3.531
                $b1t:117px; // 313 / 2.682
    
                $b2l:230px; // 313 / 1.356
                $b2t:210px; // 313 / 1.492
    
                $b3l:63px; // 313 / 5
                $b3t:225px; // 313 / 1.38888
                div.book {
                    &.b0 {
                        left: $b0l;
                        top: $b0t;
                    }
                    &.b1 {
                        left: $b1l;
                        top: $b1t;
                    }
                    &.b2 {
                        left: $b2l;
                        top: $b2t;
                        .title {
                            left:-3.5rem;
                            .label { top:1rem }
                        }
                    }
                    &.b3 {
                        left: $b3l;
                        top: $b3t;
                        .title {
                            left:-3.5rem;
                            .label { top:1rem }
                        }
                    }
                    &.flipped {
                        .title { 
                            left:-9rem;
                        }
                        &.b2 {
                            .title {
                                left:-5rem; 
                            }
                        }
                        &.b3 {
                            .title {
                                left:-5rem;
                            }
                        }
                    }
                    .title {
                        width:9rem;
                        .label {
                            font-size: small;
                            padding:0.5rem;
                            top:0px;
                        }
                    }
                }
                img.book {
                    width: 60px;
                    left: -30px; // 60 / 2
                    top: -23px; // 60 / 2.644444444
                }
                &.flipped {
                    .b0 { left: 63px !important }
                    .b1 { left: 224px !important }
                    .b2 { left: 83px !important }
                    .b3 { left: 250px !important }
                }
            }
        }
    }
    .fullScreen {
        .X {
            left:calc(50% + 190px + 1rem);
            top:1rem;
        }
        .Book {
            padding-top:2rem;
            padding-bottom:2rem;
            width:380px;
        }
    }
    pre {
        font-size: medium;
    }
}

@media (max-width: 460px) {
    .fullScreen {
        .X {
            left:calc(50% + 140px + 1rem);
            top:1rem;
        }
        .Book {
            padding-top:2rem;
            padding-bottom:2rem;
            width:280px;
        }
    }
}